import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import CompanyGrid from '../components/company-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

export const query = graphql`
  query SectorsPageQuery($id: String!) {
    page: sanityPage(_id: { regex: "/(drafts.|)company/" }) {
      title
      _rawBody
      metaTitle
      metaDescription
      metaKeywords
    }

    companies: allSanityCompany(filter: {display: {ne: false}, sectors: {elemMatch: {_id: {eq: $id}}}}, sort: {fields: [sortOrder, title], order: [DESC, ASC]}) {
      edges {
        node {
          title
          _id
          _key
          sortOrder
          investmentDate
          slug {
            current
          }
          _rawExcerpt
          link
          logo {
            asset {
              _id
              url
            }
          }
          brandColor {
            hex
          }
          mainImage {
            asset {
              _id
              url
            }
          }
          sectors {
            _id
            title
          }
          stage {
            _id
            title
          }
        }
      }
    }

    stages: allSanityStage(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`

const SectorsPage = props => {
  const { data, errors, pageContext } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const page = data.page
  // console.log(page);
  const stageNodes = (data || {}).stages
    ? mapEdgesToNodes(data.stages)
    : []
  const rawCompanyNodes =
    data && data.companies && mapEdgesToNodes(data.companies).filter(filterOutDocsWithoutSlugs)
  const unsortedCompanies = 
    rawCompanyNodes && rawCompanyNodes.filter(company => company.sortOrder === null).sort((a,b) => {
        a = a.title.toLowerCase();
        b = b.title.toLowerCase();
        if ( a == b) return 0;
        return a < b ? -1 : 1;
    });
  const sortedCompanies = 
    rawCompanyNodes && rawCompanyNodes.filter(company => company.sortOrder !== null)
  const companyNodes = [...sortedCompanies, ...unsortedCompanies];
  return (
    <Layout>
      <SEO title={`${pageContext.title} ${page.metaTitle || page.title}`} description={page.metaDescription} keywords={page.metaKeywords} />

      <Hero>
        {`${pageContext.title} ${page.title}`}
      </Hero>

      {companyNodes && companyNodes.length > 0 && <CompanyGrid nodes={companyNodes} stages={stageNodes} body={page._rawBody} />} 
    </Layout>
  )
}

export default SectorsPage
